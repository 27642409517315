.er{
  color: red;
  display: none;
}

body {
  background-color: #DFDBD7;
  color: #292929;
}

.header{
  background-color: #7696A9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  font-weight: bold;
}

.header .currentMonth{
  font-size: 30px;
}

.header button{
  all: unset;
  margin: 5px 20px 0;
}

.header button.logoutBtn{
  all: unset;
  display: block;
  width: 100px;
  padding: 7px;
  text-align: center;
  background-color: #7696A9;
  color: #DFDBD7;
  position: absolute;
  right: 0px;
  top: 26px;
  font-size: 12px;
}

.logout{
  text-align: center;
  margin: 50px;
}

.logout button.loginBtn{
  all: unset;
  display: block;
  width: 100px;
  padding: 15px;
  text-align: center;
  background-color: #7696A9;
  color: #DFDBD7;
  font-size: 15px;
  font-weight: bold;
  margin: 15px auto;
}

.logout .logo{
  width: 200px;
  height: 200px;
}

.calendar{
  width: 90%;
  margin: 50px auto;
}

.calendar .week{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #ccc;
}

.calendar .cell{
  flex: 1;
  text-align: center;
  padding: 10px 0px 10px 10px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  overflow: hidden;
}

.calendar .weekDay {
  height: 10px;
  font-size: 10px;
  font-weight: bold;
  color: #777;
}

.calendar .day {
  height: 100px;
  position: relative;
}

.calendar .day::after{
  content: "";
  display: block;
  content: "";
  background-image: url("./images/ellipsis_normal.png");
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 30px;
}

.calendar .today{
  background-color: #C8CFD4;
}

.calendar .today::after{
  background-image: url("./images/ellipsis_today.png");
}


.calendar .day .cellInnner{
  overflow: hidden;
  position: relative;
  height: 110px;
}

.calendar .day.not-current-month .cellInnner{
  opacity: 30%;
}

.calendar .day .date {
  text-align: left;
  font-weight: bold;
  font-size: 13px;
}

.calendar .day.weekend .date{
  color: #7696A9;
}

.calendar .day .date i{
  font-size: 12px;
  margin-left: 5px;
  color: #90969a;
}

.calendar .day ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.calendar .day li{
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
  color: #5F5F5F;
  white-space: nowrap
}

.calendar .day li i{
  font-size: 10px;
  margin-right: 4px;
  color: #aaa;
  padding: 3px;
  border-radius: 7px;
}

.calendar .day li.isDone i {
  color: #5f8297;
  background-color: #C8CFD4;
}

.calendar .day .stamps{
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 9px 0px;
  
  display: flex;
  white-space: nowrap;
}

.calendar .day .stamps i{
  color: #5f8297;
  background-color: #C8CFD4;
  font-size: 16px;
  margin-right: 3px;
  padding: 2px;
  border-radius: 3px;
}

.dayDataForm {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dialogBase {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(110, 110, 110, 0.7);
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  background-color: #F4F4F4;
  padding: 20px 30px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dialog .dialogClose{
  all: unset;
  position: absolute;
  top: 15px;
  right: 20px;

  font-size: 25px;
  font-weight: bold;
  color: #7696A9;
}

.dialog .date{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #7696A9;
  margin-bottom: 20px;
}

.dialog textarea, .dialog input{
  display:block;
  width: 100%;
  border: none;
  background-color: #C8CFD4;
  padding: 10px;
  box-sizing: border-box;
  color: #292929;
  font-size: 13px;
}

.dialog .record{
  display: flex;
  margin-bottom: 10px;
}

.dialog .record i {
  font-size: 14px;
  padding: 10px;
  border-radius: 17px;
  color: #d5cece;
  background-color: #dfdedd;
  margin-right: 7px;
  margin-left: -4px;
}

.dialog .record.isDone i {
    color: #5f8297;
    background-color: #C8CFD4;
}


.dialog .card{
  background-color: #ECE8E5;
  padding: 20px;
  margin-bottom: 10px;
}

.dialog textarea{
  resize: none;
  height: 100px;
}

.dialog .recordBody{
  display: none;
}

.dialog label,
.dialog .label{
  display: block;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 5px;
  color: #7b7b7b;
}
.dialog label{
  display: none;
}

.dialog .addBtn{
  all: unset;
  display: block;
  width: 100px;
  padding: 7px;
  text-align: center;
  margin: 0px auto;
  background-color: #a7b6c2;
  color: #fff;
  font-weight: bold;
}

.dialog .btns{
  text-align: center;
}

.dialog .saveBtn,
.dialog .cancelBtn{
  all: unset;
  display: inline;
  width: 100px;
  padding: 7px;
  margin: 5px 5px 0;
  text-align: center;
  background-color: #7696A9;
  color: #fff;
}

.dialog .cancelBtn{
  background-color: #a7a7a7;
}

.dialog .stamps i{
  color: #AAA;
  font-size: 18px;
  margin: 8px;
  padding: 2px;
  border-radius: 3px;
}

.dialog .stamps i[data-switch="true"] {
  color: #5f8297;
  background-color: #C8CFD4;
}

.stamps{
  color: #7696A9;
  font-size: 18px;
}

.todoList , .memo{
  width: 80%;
  max-width: 500px;
  background-color: #eae9e8;
  padding: 20px 30px;
  margin: 10px auto 30px;
  box-shadow: 0 2px 2px rgba(103, 100, 96, 0.075);
}

.memo{
  margin-bottom: 50px;
}

.todoList label,.memo label{
  display: block;
  font-weight: bold;
  font-size: 13px;
  margin-bottom: 10px;
  color: #7b7b7b;
}

.todoList ul{
  margin: 0;
  padding: 0;
}

.todoList li{
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #5e5a5a;
  white-space: nowrap;
  display: flex;
}

.memo textarea{
  font-size: 14px;
  color: #5e5a5a;
  width: 100%;
  height: 200px;
  line-height: 1.4;
}

.todoList li i{
  font-size: 18px;
  padding: 2px;
  border-radius: 3px;
  color: #d5cece;
  background-color: #dfdedd;
  margin-right: 7px;
}

.todoList li input,
.memo textarea{
  background-color: #eae9e8;
  border: none;
  border-bottom: 1px solid #d5cece;
  color: #5e5a5a;
  flex: auto;
}

.todoList li input:focus,
.memo textarea:focus {
  background-color: #d9dee1;
  outline-style: groove;
  outline-color: #d5cece;
}

.todoList li input::placeholder,
.memo textarea::placeholder {
  color: #d5cece;
}

.todoList li.isDone input{
  color: #7696A9;
  background-color: #d8dee4;
  border-radius: 3px;
}

.todoList li.isDone i{
  color: #5f8297;
  background-color: #C8CFD4;
}

.todoList .btns{
  text-align: center;
}

.todoList .addBtn,
.todoList .delBtn,
.todoList .copyBtn{
  all: unset;
  display: inline-block;
  width: 20px;
  padding: 5px;
  text-align: center;
  margin: 0px auto;
  background-color: #a7b6c2;
  color: #fff;
  font-weight: bold;
}

.todoList .delBtn{
  background-color: #bfbdbd;
  display: inline-block;
  padding: 2px;
  width: 16px;
  margin-left: 10px;
  display: block;
}

.todoList .copyBtn{
  width: auto;
  font-weight: normal;
  margin-left: 5px;
  font-size: 14px;
  padding: 6px 10px;
}

.switchAppModeButton{
  all: unset;
  padding: 5px;
  text-align: center;
  margin: 10px auto;
  display: block;
  background-color: #a7b6c2;
  color: #fff;
  font-size: 11px;
}

.weeklyMode .weekDay,
.weeklyMode .day,
.weeklyMode .calendar > div.week:nth-of-type(1),
.weeklyMode .todoList{
  display: none;

}

.weeklyMode .day{
  height: 250px;
}

.weeklyMode .week > div.day:nth-of-type(2) {
  display: block;
}

.weeklyMode .calendar{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-right: 1px solid #ccc; */
}

.weeklyMode .week{
  background-color: #eae9e8;
  margin-right: 15px;
  box-shadow: 0 2px 2px rgba(103, 100, 96, 0.075);

}

.weeklyMode .week.now-week{
  background-color: #C8CFD4;
}

.weeklyMode .calendar .day .cellInnner {
  height: 260px;
}

.weeklyMode .calendar > div.week:nth-of-type(2){
  margin-left: 50px;
}

.weeklyMode .calendar > div.week:last-child{
  margin-right: 50px;
}

.weeklyMode .calendar .day::after{
  background-image:none;
}

.weeklyMode .calendar .week.now-week .day li.isDone i,
.calendar .week .day.today li.isDone i{
  background-color: #eedfcf;
}

.weeklyMode .memo{
  background: none;
  box-shadow: none;
  margin: 20px;
  width: 90%;
  max-width: none;
}

.weeklyMode .memo textarea {
  background: none;
  color: #837e7e;
  font-size: 12px;
}

.weeklyMode .memo textarea:focus {
  background: none;
}


@media screen and (max-width: 767px) {
  .logout{
    text-align: center;
    margin: 150px 0 0 0;
  }

  .header{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .calendar{
    border-top: 1px solid #ccc;
    margin-top: 100px;
  }
  .calendar .week{
    display: block;
  }

  .calendar .weekDay,
  .calendar .not-current-month {
    display: none;
  }

  .calendar .day,
  .calendar .day .cellInnner,
  .calendar .day .cellInnner .stamps{
    height: auto;
    position: relative;
  }

  .calendar .day .cellInnner{
    min-height: 30px;
  }
  
  .calendar .day .cellInnner .stamps{
    margin-bottom: 0px;
  }

}

/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
